import loadable from '@loadable/component';
import { WithLazyHydration } from '../../../WithLazyHydration';

const ExpandedGalleryLoadable = loadable(() => import('./ExpandedGallery'), {
  resolveComponent: components => components.ExpandedGallery
});

export const ExpandedGallery = WithLazyHydration(ExpandedGalleryLoadable, {
  name: 'ExpandedGallery'
});
